.nav-toggle {
    width: 100%;
    height: 50px;
    background: #2d2d2d url("../images/menu.png");
    background-repeat: no-repeat;
    background-position: center;
    display: block;

    @include media-query(micro) {
        display: none;
    }
}

#nav {
    width: 100%;
    background: #2d2d2d;

    @include media-query(micro) {
        padding: 26px 0;
        margin: 0 0 20px 0;
    }
    .content-wrap {
        .nav-wrap {
            padding: 0;
            margin: 0;

            @include media-query(micro) {
                padding: 0 2em;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            @include media-query(milli) {
                margin: 0 auto;
                float: right;
            }

            @include media-query(normal) {
                padding: 0;
            }
            li {
                list-style: none;
                text-align: center;
                padding: 0.35em 0;
                
                @include media-query(micro) {
                    padding: 0;
                    float: left;
                    margin: 0 14px;
                }
                a {
                    color: #ffffff;
                    text-transform: lowercase;
                    font-family: maven_pro_bold;
                    font-size: 19px;
                    text-decoration: none;
                    display: block;

                    @include media-query(micro) {
                        display: inline-block;
                    }
                    @include media-query(milli) {
                        font-size: 20px;
                    }

                    &:hover {
                        color: #8fd7f1;
                    }

                    + .selected {
                        color: #8fd7f1;
                    }
                }
            }
        }
    }
}
.-closed {
    display: none;
}
