#footer {
    margin-top: 40px;
    color: #ffffff;
    .donate {
        background: #ff5e5e;
        padding: 30px 0 20px 0;
        position: relative;

        .content-wrap {
            max-width: 960px;
            margin: 0 auto;
            padding: 0 2em;

            p {
                margin: 0;

                a {
                    text-decoration: none;
                    font-family: maven_pro_bold;
                    color: #8fd7f1;
                }
            }
            .donate-icon {
                width: 258px;
                height: 44px;
                display: block;
                position: absolute;
                top: -20px;
            }
            p {

            }
        }
    }
    .footer-nav {
        background: #2d2d2d;
        padding: 30px 0;

        .content-wrap {
            padding: 0 2em;
            margin: 0 auto;

            .footer-item {
                float: left;
                width: 100%;

                @include media-query(nano) {
                    width: 50%;

                }

                @include media-query(milli) {
                    width: 25%;
                }

                a {
                    color: #ffffff;
                    text-decoration: none;
                    text-transform: lowercase;

                    &:hover {
                        color: #8fd7f1;
                    }
                }
                .selected {
                    color: #8fd7f1;
                }
            }
            .browse {
                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        list-style: none;
                        margin-bottom: 8px;
                    }
                }
            }
            .address {

            }
            .details {

            }
            .social-links {
                .social-media {
                    height: 40px;
                    width: 220px;
                    display: block;
                    margin-bottom: 12px;
                }
                .fb-like {
                    background-position: 0 -52px;
                }
                .fb-share {
                    background-position: 0 -93px;
                }
                .instagram {
                    background: URL("../images/instagram.png");
                    background-position: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 9px;
                }
                .addthis_toolbox {
                    margin-top: 30px;
                }
            }
        }
    }
}