#header {
    background: #ff5e5e;
    font-family: maven_pro_bold;
    position: relative;

    @include media-query(normal) {
        padding: 20px 0;
        width: 100%;
        height: 145px;
        margin: 0;
    }

    .content-wrap {
         max-width: 960px;
         margin: 0 auto;
         padding: 2em;

        @include media-query(normal) {
            padding: 0;
        }
        .title {
            display: none;

            @include media-query(normal) {
                display: block;
                float: left;
                overflow: hidden;
                width: 100px;
                height: 100px;
                font-size: 16px;
                margin: 30px 125px 30px 30px;
            }
        }
        #logo {
            margin: 0 auto;
            width: 222px;
            display: block;

            @include media-query(micro) {
                display: inline-block;
                float: left;
            }

            @include media-query(normal) {
                position: absolute;
                float: none;
                display: block;
            }
        }
        .strapline {
            line-height: 32px;
            font-size: 20px;
            text-align: center;
            display: block;
            margin: 0.25em auto;
            color: #ffffff;

            @include media-query(micro) {
                margin: 2rem auto;
                font-size: 26px;
            }

            @include media-query(normal) {
                float: left;
                width: 370px;
                margin-top: 50px;
            }
        }
        .header-details {
            text-align: center;
            width: 100%;

            @include media-query(normal) {
                width: 300px;
                float: right;
            }
            .phoneNo {
                color: #2d2d2d;
                text-decoration: none;
                font-size: 20px;
                display: block;
                margin: 0.5em 0;

                @include media-query(micro) {
                    font-size: 26px;
                }

                @include media-query(normal) {
                    font-size: 32px;
                    float: right;
                    line-height: 42px;
                    margin: 20px 0 24px 0;
                }

                &:hover {
                    color: #8fd7f1;
                }
            }
            .email {
                color: #ffffff;
                text-decoration: none;
                font-size: 20px;
                display: block;
                margin: 0.5em 0 0 0 ;

                @include media-query(normal) {
                    font-size: 22px;
                    float: right;
                    margin: 0;
                }

                &:hover {
                    color: #8fd7f1;
                }
            }
        }
    }
}