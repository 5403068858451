.main-content {
    .contact-form {
        input {
            width: 100%;

            @include media-query(micro) {
                width: 70%;
                display: block;
            }
        }
        textarea {
            width: 100%;

            @include media-query(micro) {
                width: 70%;
                display: block;
            }
        }
    }
}
.error {
    color: #ff5e5e;
    font-family: maven_pro_bold;
}