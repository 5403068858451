/*--------------------------------------
Main Styles
----------------------------------------*/

@font-face {
    font-family: 'maven_pro_bold';
    src: url('../fonts/mavenpro-bold-webfont.eot');
    src: url('../fonts/mavenpro-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/mavenpro-bold-webfont.woff') format('woff'),
    url('../fonts/mavenpro-bold-webfont.ttf') format('truetype'),
    url('../fonts/mavenpro-bold-webfont.svg#maven_probold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'maven_pro_regular';
    src: url('../fonts/mavenpro-regular-webfont.eot');
    src: url('../fonts/mavenpro-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/mavenpro-regular-webfont.woff') format('woff'),
    url('../fonts/mavenpro-regular-webfont.ttf') format('truetype'),
    url('../fonts/mavenpro-regular-webfont.svg#maven_proregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

html, body {
    padding: 0;
    margin: 0;
    font-family: maven_pro_regular;
    color: #1d1d1d;
    background: #ffffff;
}

label {
    display: block;
    margin: 16px 0 4px 0;
}

input[type="text"],
textarea {
    padding: 0.5em;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -khtml-border-radius: 6px;
    border: 1px solid #57c2e9;
}

input[type="submit"] {
    padding: 0.5em 0.8em;
    background: #ff5e5e;
    border: 0;
    color: #ffffff;
    font-family: maven_pro_bold;
    font-size: 20px;
    letter-spacing: 1px;
    margin: 20px 0;
}

input[type="submit"]:hover {
    background: #2d2d2d;
    border: 0;
    color: #ffffff;
    cursor: pointer;
}

textarea {
    min-height: 100px;
}

hr {
    width: 100%;
    border-top: 1px solid #ffffff;
}

a img {
    border: 0;
}

/*--------------------------------------
Links
----------------------------------------*/

a {
    color: #ff5e5e;
    text-decoration: underline;
}

/*--------------------------------------
Typography
----------------------------------------*/

h1 {
    float: left;
    overflow: hidden;
    width: 100px;
    height: 100px;
    font-size: 16px;
    margin: 30px 125px 30px 30px;
}

h2 {
    font-family: maven_pro_bold;
    color: #ffffff;
    font-size: 22px;
    font-weight: normal;
    text-transform: lowercase;
}

h3 {
    font-family: maven_pro_bold;
    color: #2d2d2d;
    font-size: 20px;
    font-weight: normal;
    text-transform: lowercase;
    margin: 0 0 0.5em 0;
}

p {
    line-height: 24px;
}

strong {
    font-family: maven_pro_bold;
    font-weight: normal;
}

/*--------------------------------------
Common
----------------------------------------*/

.clearfix:after {
    clear: both;
    content: " ";
    display: block;
}

.content-wrap {
    max-width: 960px;
    margin: 0 auto;
}

.right {
    float: right;
}

.sprite {
    background: url('../images/sprite.png');
}

.crumbtrail a {
    font-family: maven_pro_bold;
    text-decoration: none;
    color: #8fd7f1;
    text-transform: lowercase;
}

.crumbtrail p {
    margin: 0;
}