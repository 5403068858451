.content {
    .content-wrap {
        max-width: 960px;
        margin: 0 auto;
        padding: 2em 2em 0 2em;
        display: flex;
        flex-direction: column;

        @include media-query(milli) {
            display: block;
        }

        @include media-query(normal) {
            padding: 0;
        }


        .main-content {
            line-height: 24px;
            float: left;
            display: block;

            @include media-query(milli) {
                width: 70%;
            }

            @include media-query(normal) {
                width: 615px;
                margin: 20px 0;
            }
            .graphic-links {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;

                .image-link {
                    text-decoration: none;
                    position: relative;
                    display: block;
                    margin-bottom: 20px;

                    .image {
                        display: block;
                        width: 100%;

                        img {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                    .title-wrap {
                        background: #063a83;
                        position: absolute;
                        margin: 0 -10px 16px 0;
                        padding: 0 10px;
                        display: inline-block;
                        right: 0;
                        bottom: 0;
                        min-width: 228px;
                        text-align: center;
                    }
                }
                .small-image-link {
                    text-decoration: none;
                    position: relative;
                    margin: 0 0 20px 0;
                    float: left;
                    max-width: 48%;

                    @include media-query(normal) {
                        width: auto;
                    }

                    .image {
                        display: block;

                        @include media-query(normal) {
                            background: #ff0000;
                            width: 295px;
                            height: 197px;
                        }
                        img {
                            max-width: 100%;
                        }
                    }
                    .title-wrap {
                        padding: 0 10px;
                        position: absolute;
                        display: inline-block;
                        right: 0;
                        bottom: 0;
                        text-align: center;
                        margin: 0 -10px 16px 0;

                        @include media-query(milli) {
                            min-width: 228px;

                        }

                        h2 {
                            font-size: 17px;

                            @include media-query(milli) {
                                font-size: 22px;
                            }
                        }
                    }
                }
                .left-box {
                    margin-right: 16px;

                    @include media-query(milli) {
                        margin-right: 22px;
                    }
                }
            }
            .mission-statement {
                padding: 10px 20px;
                position: relative;

                p {
                    position: relative;
                    z-index: 10;
                }
                .quotemark {
                    display: block;
                    width: 38px;
                    height: 32px;
                    position: absolute;
                    z-index: 4;
                    top: 60px;
                    left: 18px;
                    background-position: -236px -55px;
                }
                .quotemark2 {
                    display: block;
                    width: 38px;
                    height: 32px;
                    position: absolute;
                    z-index: 4;
                    bottom: 16px;
                    right: 18px;
                    background-position: -239px -89px;
                }
            }
        }
    }
}

//Colours for homepage boxes.

.green {
    background: #0a8453;
}

.blue {
    background: #57c2e9;
}

.orange {
    background: #f9a502;
}

.red {
    background: #ff5e5e;
}