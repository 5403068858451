/* best size: 274 x 63 */ .login h1 a {
    background-image: url("../images/scbu-wp-login.png");
    } 

#loginform {
    border-color:#8fd7f1;
    border-width: 5px;
}

.login label {
    color: #ff7686;
}

.wp-core-ui .button-primary{
    background-color:#00c0eb;
    background-image:none;
    border-color:#00c0eb;
    border-bottom-color:#00c0eb;
    color:#ffffff;
}

.wp-core-ui .button-primary:hover {
    background-color:#5ecbee;
    background-image:none;
    border-color:#5ecbee;
    color:#ffffff;
}

/* 

body.login {
2	background-image: url('bg.png');
3	}

.login form {
	background: rgba(0, 0, 0, .6);
	} 
    
    .login label {
2	font-family:"Courier New", Courier, monospace;
3	font-size: 28px;
4	color: #33d42a;
5	}

.login input[type="text"]{
2	font-family:"Courier New", Courier, monospace;
3	font-size:24px;
4	background-color:rgba(0,0,0,.6);
5	border-color:#33d42a;
6	}
7	 
8	.login input[type="password"]{
9	background-color:rgba(0,0,0,.6);
10	border-color:#33d42a;
11	}

.wp-core-ui .button-primary{
2	background-color:#000;
3	background-image:none;
4	border-color:#33d42a;
5	border-bottom-color:#13f278;
6	color:#33d42a;
7	}
8	 
9	.wp-core-ui .button-primary:hover {
10	background-color:#33d42a;
11	background-image:none;
12	border-color:#33d42a;
13	color:#000;
14	}

.login #nav a, .login #backtoblog a {
2	color: #33d42a !important;
3	text-shadow:none;
4	}
5	 
6	.login #nav a:hover, .login #backtoblog a:hover {
7	color: #33d42a !important;
8	text-shadow:0 1px 0 #33d42a;
9	}
*/