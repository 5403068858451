.main-content {
    .gallery-page {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        img {
            margin: 0 10px 10px 0;
            height: 140px;
            width: auto;
            border: 1px solid #eeeeee;
            transition: .25s ease-out;
            -moz-transition: .25s ease-out;
            -webkit-transition: .25s ease-out;
            -o-transition: .25s ease-out;

            &:hover {
                border: 1px solid #E3E3E3;
                -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
                filter: alpha(opacity=80);
                -moz-opacity:0.8;
                -khtml-opacity: 0.8;
                opacity: 0.8;
            }
        }
    }
}