.sidebar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px 0 0 0;

    @include media-query(milli) {
        width: 27%;
        float: right;
        margin: 0;
    }

    @include media-query(normal) {
        float: right;
        width: 320px;
        margin: 20px 0;
    }
    .facebook-widget {
        width: 100%;
        background: #063a83;
        padding: 20px 20px 10px 20px;

        @include media-query(nano) {
            width: 45%;
        }

        @include media-query(milli) {
            width: 83;
        }

        @include media-query(normal) {
            width: 100%;
        }
        p {
            color: #ffffff;
            margin: 8px 0;
            line-height: 20px;
        }
        .facebook {
            width: 118px;
            display: block;
            height: 40px;
            margin: 0 0 0 -4px;

            @include media-query(normal) {
                width: 289px;
            }
        }
        .f-like {
            background-position: 0 -179px;
        }
        .f-group {
            background-position: 0 -138px;
        }
    }
    .donate-widget {
        background: #57c2e9;
        position: relative;
        width: 100%;

        @include media-query(nano) {
            width: 90% !important;
            max-width: 50%;
        }

        @include media-query(milli) {
            width: 100% !important;
            max-width: 100%;
        }

        .tabs {
            margin: 0;
            padding: 0;
            float: left;
            list-style: none;
            width: 100%;

            li {
                float: left;
                margin: 0;
                padding: 10px 0;
                overflow: hidden;
                position: relative;
                text-align: center;
                width: 50%;
                background: #ff5e5e;

                a {
                    text-decoration: none;
                    font-family: maven_pro_bold;
                    text-transform: lowercase;
                    color: #ffffff;
                    display: block;
                    font-size: 15px;
                    padding: 0 20px;
                    outline: none;

                    @include media-query(micro) {
                        font-size: 18px;
                    }
                }
            }
            .active {
                background: #57c2e9;
            }
        }

        .tab_container {
                clear: both;
                float: left;
                padding: 18px 20px 10px 20px;

            .tab_content {
                .justgivingdonationform {
                    input[type="text"] {
                        width: 100%;
                        padding: 0.8em 0em 0.8em 0.25em;
                        margin-top: 8px;
                    }
                    input[type="submit"] {
                        margin: 20px 0 0 0;
                        padding: 0.5em 0.8em;
                        background: #ff5e5e;
                        border: 0;
                        color: #ffffff;
                        font-family: maven_pro_bold;
                        font-size: 20px;
                        letter-spacing: 1px;
                    }
                    .donation-amount {
                        width: 48%;
                        display: inline-block;
                        font-family: maven_pro_bold;
                        color: #ffffff;
                        margin: 0 0 16px 0;
                        text-align: center;
                    }
                }
            }
        }
        .just-giving {
            display: none;

            @include media-query(normal) {
                width: 110px;
                height: 21px;
                display: block;
                background-position: 0 -225px;
                position: absolute;
                right: 20px;
                bottom: 20px;
            }
        }
    }
    .subnav {
        padding: 20px 20px 10px 20px;
        background: #ff5e5e;

        ul {
            padding: 0;
            margin: 0;

            li {
                padding: 0;
                margin: 0 0 10px 0;
                list-style: none;
                background: url('../images/nav-arrow.png');
                background-position: 0 6px;
                background-repeat: no-repeat;
                min-height: 22px;

                a {
                    text-decoration: none;
                    font-family: maven_pro_bold;
                    color: #ffffff;
                    font-size: 18px;
                    margin: -4px 0 0 14px;

                    &:hover {
                        color: #8fd7f1;
                    }
                }
            }
            .current_page_item {
                background: url('../images/nav-arrow-on.png');
                background-position: 0 6px;
                background-repeat: no-repeat;

                a {
                    color: #8fd7f1;
                }
            }
        }
    }
    .widget {
        margin-bottom: 20px;
        width: 100%;

        @include media-query(nano) {
            width: 40%;
        }

        @include media-query(milli) {
            width: 100%;
        }

        &:last-child {
            @include media-query(normal) {
                margin: 0;
            }
        }
    }
}